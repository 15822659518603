import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "hooks/store.hooks";

import userApi from "api/user/user";

import { setUser } from "store/user/user-store.slice";

interface AuthenticationWrapperProps {
    children: JSX.Element;
}

const AuthenticationWrapper: React.FunctionComponent<AuthenticationWrapperProps> = (
    props
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);

    React.useEffect(() => {
        if (!user.id) {
            userApi
                .requestMeGet()
                .then((response) => {
                    dispatch(setUser(response.data));
                })
                .catch(() => {
                    navigate("/login");
                });
        } else {
            navigate("/user");
        }
    }, [user]);

    return props.children;
};

export default AuthenticationWrapper;
