type Permission = "guest" | "user" | "admin";

type NavigationItemId = "home" | "login" | "dashboard" | "reservation" | "logout";

interface NavigationItem {
    id: NavigationItemId;
    label: string;
    icon: string;
    path: string;
    permissions: Permission[];
}

const navigationItems: NavigationItem[] = [
    // https://icons.getbootstrap.com
    {
        id: "home",
        label: "Home",
        icon: "house",
        path: "/",
        permissions: ["user"],
    },
    {
        id: "dashboard",
        label: "Dashboard",
        icon: "speedometer",
        path: "/user",
        permissions: ["user"],
    },
    {
        id: "reservation",
        label: "Reservation",
        icon: "journal-plus",
        path: "/user/reservation",
        permissions: ["user"],
    },
];

export type { Permission, NavigationItem };
export { navigationItems };
