import React from "react";

interface VerifyEmailProps {
    className?: string;
}

const VerifyEmail: React.FunctionComponent<VerifyEmailProps> = (props) => {
    const { className } = props;

    return (
        <div className={className}>
            <h1>Successful registration!</h1>
            <h2 className="mt-3">Welcome to the community!</h2>
            <p className="mt-3">
                You are only just a few steps away from completing your
                registration.<br/>To activate your account you need to <strong>verify your email address</strong>:
                <ul className="mt-3">
                    <li className="mb-2">Check your emails</li>
                    <li className="mb-2">Open the mail we sent to you (from info@erider.hu)</li>
                    <li className="mb-2">Click on the activation link</li>
                </ul>
            </p>
        </div>
    );
};

export default VerifyEmail;
