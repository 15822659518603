import React from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { useAppSelector } from "hooks/store.hooks";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "pages/home";
import Login from "pages/login";
import Dashboard from "pages/dashboard";
import NotFound from "pages/not-found";
import Reservation from "pages/reservation";

import Navigation from "components/navigation";
import AuthenticationWrapper from "components/authentication-wrapper";
import LoadingStripe from "components/loading-stripe";
import { navigationItems } from "components/navigation/config";
import Toast, { TOAST_TIMEOUT } from "components/toast";
import InfoBar from "components/info-bar";

import { setToastVisible } from "store/toast/toast-store.slice";

import styles from "./app.module.css";

const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const toast = useAppSelector((state) => state.toast);
    const user = useAppSelector((state) => state.user);
    const loading = useAppSelector((state) => state.app.loading);

    const menuItems = React.useMemo(() => {
        return navigationItems.filter((item) =>
            item.permissions.includes(user.id ? "user" : "guest")
        );
    }, [navigationItems, user]);

    const handleToastClose = React.useCallback(
        () => dispatch(setToastVisible(false)),
        []
    );

    return (
        <Router>
            <div
                className={classnames(
                    "shadow-sm bg-light position-fixed w-100",
                    styles.header
                )}
            >
                <div className={styles.infoBarContainer}>
                    <InfoBar className={styles.infoBar} />
                </div>
                <Navigation
                    className={classnames("pt-1 pb-1 pt-md-4 pb-md-4", styles.navigation)}
                    navigationItems={menuItems}
                    loggedIn={Boolean(user.id)}
                />
                <LoadingStripe loading={loading} />
            </div>
            <div className={styles.container}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/login"
                        element={
                            <AuthenticationWrapper>
                                <Login />
                            </AuthenticationWrapper>
                        }
                    />
                    <Route
                        path="/user"
                        element={
                            <AuthenticationWrapper>
                                <Dashboard />
                            </AuthenticationWrapper>
                        }
                    />
                    <Route path="/user/reservation" element={<Reservation />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Toast
                show={Boolean(toast.visible)}
                content={toast.content}
                timeout={TOAST_TIMEOUT}
                onClose={handleToastClose}
            />
        </Router>
    );
};

export default App;
