const DOMAIN_BASE_URL = "https://eride.hu:8443/api";

const authErrorMap = new Map<string, { hu: string; en: string; de: string }>();

authErrorMap.set("auth.registration.email.existing", {
    hu: "A megadott email cím már regisztrálva van",
    en: "The provided email address is already registered",
    de: "Die angegebene E-Mail-Adresse ist bereits registriert",
});
authErrorMap.set("auth.login.user-disabled", {
    hu: "A felhasználó nincs aktiválva",
    en: "User is not activated",
    de: "Der Benutzer ist nicht aktiviert",
});
authErrorMap.set("auth.login.bad-credentials", {
    hu: "Email cím vagy jelszó nem megfelelő",
    en: "Email address or password is not correct",
    de: "E-Mail-Adresse oder Passwort sind nicht korrekt",
})

export { DOMAIN_BASE_URL, authErrorMap };
