import React from "react";
import { useTranslation } from "react-i18next";

import FormField, { FormFieldProperty } from "components/form-field";
import { Field, getValidateField } from "components/form-field/util";
import PasswordStrengthMeter from "components/password-strength-meter";
import { PASSWORD_PATTERN } from "components/form-field/config";

interface RegistrationForm {
    firstname: FormFieldProperty;
    lastname: FormFieldProperty;
    email: FormFieldProperty;
    phone: FormFieldProperty;
    password: FormFieldProperty;
    confirmPassword: FormFieldProperty;
}

interface SignUpProps {
    onSubmit: (form: RegistrationForm) => void;
    className?: string;
}

const formDataInitialState: RegistrationForm = {
    firstname: { value: "" },
    lastname: { value: "" },
    email: { value: "" },
    phone: { value: "" },
    password: { value: "" },
    confirmPassword: { value: "" },
};

const SignUp: React.FunctionComponent<SignUpProps> = (props) => {
    const { onSubmit, className } = props;
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [formData, setFormData] =
        React.useState<RegistrationForm>(formDataInitialState);

    const { t } = useTranslation();

    React.useEffect(() => {
        if (
            formData.firstname.status === "valid" &&
            formData.lastname.status === "valid" &&
            formData.email.status === "valid" &&
            formData.password.status === "valid" &&
            formData.password.value === formData.confirmPassword.value
        ) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [formData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: getValidateField(name as Field, value),
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitDisabled(true);
        onSubmit(formData);
    };

    return (
        <div className={className}>
            <h1>{t("Sign up")}</h1>
            <p className="text-secondary">
                {t("And be a member of the community today and reserve a bike")}
            </p>
            <form onSubmit={handleSubmit}>
                <div className="form-group pb-2">
                    <FormField
                        type="text"
                        id="firstname-input"
                        name="firstname"
                        label={t("First name")}
                        className="form-control"
                        value={formData.firstname.value}
                        onChange={handleChange}
                        required
                        status={formData.firstname.status}
                    />
                </div>
                <div className="form-group pb-2">
                    <FormField
                        type="text"
                        id="lastname-input"
                        name="lastname"
                        label={t("Last name")}
                        className="form-control"
                        value={formData.lastname.value}
                        onChange={handleChange}
                        required
                        status={formData.lastname.status}
                    />
                </div>
                <div className="form-group pb-2">
                    <FormField
                        type="email"
                        id="registration-email-input"
                        name="email"
                        label={t("Email")}
                        className="form-control"
                        value={formData.email.value}
                        onChange={handleChange}
                        placeholder="example@domain.com"
                        required
                        status={formData.email.status}
                    />
                </div>
                <div className="form-group pb-2">
                    <FormField
                        type="tel"
                        id="phone-input"
                        name="phone"
                        label={t("Phone")}
                        className="form-control"
                        value={formData.phone.value}
                        onChange={handleChange}
                        placeholder="+36001234567"
                        status={formData.phone.status}
                    />
                </div>
                <div className="form-group pb-2">
                    <FormField
                        type="password"
                        id="registration-password-input"
                        name="password"
                        label={t("Password")}
                        className="form-control"
                        value={formData.password.value}
                        onChange={handleChange}
                        placeholder={t("Password")}
                        required
                        pattern={PASSWORD_PATTERN}
                        title={t(
                            "Password must be between 8 and 25 characters and must cointain at least a letter and a number"
                        )}
                        status={formData.password.status}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        {t(
                            "Password must be between 8 and 25 characters and must cointain at least a letter and a number"
                        )}
                    </small>
                    <PasswordStrengthMeter password={formData.password.value} />
                </div>
                <div className="form-group pb-2">
                    <FormField
                        type="password"
                        id="registration-password-input-confirm"
                        name="confirmPassword"
                        label={t("Confirm password")}
                        className="form-control"
                        value={formData.confirmPassword.value}
                        onChange={handleChange}
                        placeholder={t("Password again")}
                        required
                        pattern={PASSWORD_PATTERN}
                        status={
                            formData.password.value.length > 0
                                ? formData.password.value ===
                                  formData.confirmPassword.value
                                    ? "valid"
                                    : "invalid"
                                : undefined
                        }
                    />
                </div>
                <div className="pt-1 pb-1">
                    <i>
                        <span className="text-danger">* {t("Required field")}</span>
                    </i>
                </div>
                <button
                    disabled={submitDisabled}
                    type="submit"
                    className="btn btn-primary p-3 p-md-2 w-100 mt-2 mb-2"
                >
                    {t("RegisterCTA")}
                </button>
            </form>
        </div>
    );
};

export type { RegistrationForm };
export default SignUp;
