import React from "react";
import { useTranslation } from "react-i18next";

import FormField from "components/form-field";
import type { FormFieldProperty } from "components/form-field";
import { Field, getValidateField } from "components/form-field/util";

interface LoginForm {
    email: FormFieldProperty;
    password: FormFieldProperty;
}

interface SignInProps {
    onSubmit: (form: LoginForm) => void;
    className?: string;
}

const formDataInitialState: LoginForm = {
    email: { value: "", status: undefined },
    password: { value: "", status: undefined },
};

const SignIn: React.FunctionComponent<SignInProps> = (props) => {
    const { onSubmit, className } = props;

    const { t } = useTranslation();

    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [formData, setFormData] = React.useState<LoginForm>(formDataInitialState);

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: getValidateField(name as Field, value),
        }));
    }, []);

    const handleSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(formData);
        },
        [formData, onSubmit]
    );

    React.useEffect(() => {
        if (formData.email.status === "valid" && formData.password.status === "valid") {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [formData]);

    return (
        <div className={className}>
            <h1>{t("Login")}</h1>
            <p className="text-secondary">
                {t("If you are already a member of the community")}
            </p>
            <form onSubmit={handleSubmit}>
                <div className="form-group pb-1">
                    <FormField
                        id="email-input"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="example@domain.com"
                        onChange={handleChange}
                        label={t("Email")}
                        status={formData.email.status}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        {t("We'll never share your email with anyone else.")}
                    </small>
                </div>
                <div className="form-group pb-2">
                    <FormField
                        id="password-input"
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder={t("Password")}
                        onChange={handleChange}
                        label={t("Password")}
                        status={formData.password.status}
                    />
                </div>
                <button
                    type="submit"
                    disabled={submitDisabled}
                    className="btn btn-primary p-3 p-md-2 w-100 mt-2"
                >
                    {t("LoginCTA")}
                </button>
            </form>
        </div>
    );
};

export type { LoginForm };
export default SignIn;
