abstract class RequestAbstract {
    constructor() {
    };

    private loadingSessionCount: number = 0;

    public isLoading() { 
        if (this.loadingSessionCount === 0) return false;
        return true;
    };
    
    public setLoading(state: boolean) { 
        if (state) this.loadingSessionCount++;
        else this.loadingSessionCount--;
    };
}

export default RequestAbstract;