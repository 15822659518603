import React from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import authentication from "api/authentication";
import user from "api/user";

import { setUser } from "store/user/user-store.slice";
import { setLoading } from "store/app/app-store.slice";

import SignUp from "./components/sign-up";
import SignIn from "./components/sign-in";
import type { RegistrationForm } from "./components/sign-up";
import type { LoginForm } from "./components/sign-in";

import { LoginFormDTO, RegistrationFromDTO } from "api/authentication/type";

import styles from "./login.module.css";
import { authErrorMap } from "api/authentication/error-codes";
import { setToastContent, setToastVisible } from "store/toast/toast-store.slice";
import VerifyEmail from "./components/verify-email";

const Login: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [registered, setRegistered] = React.useState(false);

    const handleSubmitLogin = React.useCallback(async (form: LoginForm) => {
        dispatch(setLoading("loading"));

        const loginPayload: LoginFormDTO = {
            email: form.email.value,
            password: form.password.value,
        };

        authentication
            .requestLoginPost(loginPayload)
            .then(() => {
                user.requestMeGet()
                    .then((meResponse) => {
                        navigate("/user");
                        dispatch(setLoading("success"));
                        dispatch(setUser(meResponse.data));
                    })
                    .catch(() => {
                        dispatch(setLoading("error"));
                    });
            })
            .catch((e) => {
                const errorMessage = authErrorMap.get(e.response.data.code);

                if (errorMessage) {
                    dispatch(setToastContent(errorMessage["hu"]));
                    dispatch(setToastVisible(true));
                }

                dispatch(setLoading("error"));
            });
    }, []);

    const handleSubmitRegistration = React.useCallback((form: RegistrationForm) => {
        dispatch(setLoading("loading"));

        const payload: RegistrationFromDTO = {
            firstname: form.firstname.value,
            lastname: form.lastname.value,
            email: form.email.value,
            password: form.password.value,
        };

        authentication
            .requestRegistrationPost(payload)
            .then(async () => {
                dispatch(setLoading("success"));
                setRegistered(true);
            })
            .catch((e) => {
                const errorMessage = authErrorMap.get(e.response.data.code);

                if (errorMessage) {
                    dispatch(setToastContent(errorMessage["hu"]));
                    dispatch(setToastVisible(true));
                }

                dispatch(setLoading("error"));

                return;
            });
    }, []);

    return (
        <div className="d-flex flex-column flex-md-row mt-3">
            <SignIn
                onSubmit={handleSubmitLogin}
                className={classnames(
                    "d-flex flex-column w-100 w-md-50 pe-0 pe-md-4 pb-4 pb-md-0",
                    styles.signIn
                )}
            />
            {registered ? (
                <VerifyEmail className="d-flex flex-column w-100 w-md-50 ps-0 ps-md-4 pt-4 pt-md-0" />
            ) : (
                <SignUp
                    onSubmit={handleSubmitRegistration}
                    className="d-flex flex-column w-100 w-md-50 ps-0 ps-md-4 pt-4 pt-md-0"
                />
            )}
        </div>
    );
};

export default Login;
