import React from "react";
import styles from "./styles.module.css";
import classnames from "classnames";

interface ToastProps {
    show: boolean;
    content?: string;
    timeout: number;
    onClose?: () => void;
}

const Toast: React.FunctionComponent<ToastProps> = (props) => {
    const { show, content, timeout, onClose } = props;

    React.useEffect(() => {
        if (show) setTimeout(() => onClose && onClose(), timeout);
    }, [show]);

    return (
        <div
            onClick={onClose}
            style={{ animationDuration: `${timeout}ms` }}
            className={classnames(
                "rounded text-white p-4 d-flex",
                styles.toast,
                {
                    [styles.show]: show,
                    [styles.countdownAnimation]: show,
                }
            )}
        >
            <div className="d-flex align-items-center">
                <i className="bi bi-exclamation-circle-fill text-danger me-4 fs-3" />
            </div>
            <div className="d-flex align-items-center">{content}</div>
        </div>
    );
};

export default Toast;
