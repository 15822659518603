import React from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import authentication from "api/authentication";

import { setUser } from "store/user/user-store.slice";

import logo from "./assets/ebike-logo.svg";
import type { NavigationItem } from "./config";
import styles from "./navigation.module.css";

interface NavigationProps {
    className?: string;
    navigationItems: NavigationItem[];
    loggedIn: boolean;
}

const Navigation: React.FunctionComponent<NavigationProps> = (props) => {
    const { className, navigationItems, loggedIn } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleLogout = React.useCallback(() => {
        authentication.requestLogoutPost().then(() => {
            dispatch(setUser(undefined));
            navigate("/login");
        });
    }, []);

    return (
        <nav
            className={classnames(
                "d-flex align-items-center justify-content-between nav",
                className
            )}
        >
            <ul className={styles.menuList}>
                {navigationItems.map((item) => (
                    <li key={item.id}>
                        <Link className="nav-link" key={item.id} to={item.path}>
                            <i className={`bi bi-${item.icon} me-1`}></i>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
            <img src={logo} className={styles.eRideLogo} alt="ebike-logo" />
            <ul className={styles.auxiliaryMenuList}>
                {loggedIn ? (
                    <li>
                        <button
                            type="button"
                            onClick={handleLogout}
                            className="btn btn-outline-primary"
                        >
                            <i className="bi bi-door-closed me-0 me-md-1" />
                            <span className="d-none d-md-inline">{t("Logout")}</span>
                        </button>
                    </li>
                ) : (
                    <li>
                        <Link className="btn btn-primary" to="/login">
                            <i className="bi bi-key-fill me-0 me-md-1" />
                            <span className="d-none d-md-inline">{t("LoginCTA")}</span>
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Navigation;
