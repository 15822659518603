import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";

interface PasswordStrengthMeterProps {
    password: string;
}

const PasswordStrengthMeter: React.FunctionComponent<PasswordStrengthMeterProps> = (
    props
) => {
    const { password } = props;
    const [strength, setStrength] = React.useState<number>(0);
    const { t } = useTranslation();

    const evaluatePasswordStrength = (password: string): number => {
        let strength = 0;

        if (password.length >= 8) strength += 1;
        if (password.match(/[a-z]/)) strength += 1;
        if (password.match(/[A-Z]/)) strength += 1;
        if (password.match(/[0-9]/)) strength += 1;
        if (password.match(/[^a-zA-Z0-9]/)) strength += 1;

        return strength;
    };

    const getStrengthLabel = () => {
        switch (strength) {
            case 2:
                return t("Weak");
            case 3:
                return t("Moderate");
            case 4:
                return t("Strong");
            case 5:
                return t("Very strong");
            default:
                return t("Very weak");
        }
    };

    React.useEffect(() => {
        setStrength(evaluatePasswordStrength(password));
    }, [password]);

    return (
        <div>
            <div className={styles.passwordStrengthMeter}>
                <div
                    className={classnames(
                        styles.passwordStrengthMeterFill,
                        styles[`strength${strength}`]
                    )}
                ></div>
            </div>
            <p>
                {t("Password strength")}: {getStrengthLabel()}
            </p>
        </div>
    );
};

export default PasswordStrengthMeter;
