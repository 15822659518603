import React from "react";
import store from "store";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";

import hungarian from "configs/languages/hungarian.json";
import english from "configs/languages/english.json";
import german from "configs/languages/german.json";

import App from "./app";
import reportWebVitals from "./reportWebVitals";
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

i18n.use(initReactI18next).init({
    // manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
        en: english,
        hu: hungarian,
        de: german,
    },
    lng: "hu",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

root.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <App />
        </StoreProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
