import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user";
import appReducer from "./app";
import toastReducer from "./toast";

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    toast: toastReducer,
});

export default rootReducer;
