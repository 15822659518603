import React from "react";
import classnames from "classnames";
import i18next from "i18next";
import 'dayjs/locale/hu';
import 'dayjs/locale/de';
import 'dayjs/locale/en';

import { useAppDispatch, useAppSelector } from "hooks/store.hooks";

import { LanguageCode } from "store/app/type";
import { setLanguage } from "store/app/app-store.slice";

import { ReactComponent as FlagHU } from "./assets/hu.svg";
import { ReactComponent as FlagGB } from "./assets/gb.svg";
import { ReactComponent as FlagDE } from "./assets/de.svg";
import styles from "./styles.module.css";


interface LanguageSelectorProps {
    classNames?: string;
}

interface Language {
    label: string;
    flag: JSX.Element;
}

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = (props) => {
    const { classNames } = props;
    const dispatch = useAppDispatch();
    const selectedLanguage = useAppSelector((state) => state.app.language);

    const flagMap = new Map<LanguageCode, Language>();

    flagMap.set("hu", { label: "Magyar", flag: <FlagHU className={styles.flag} /> });
    flagMap.set("en", { label: "English", flag: <FlagGB className={styles.flag} /> });
    flagMap.set("de", { label: "Deutsch", flag: <FlagDE className={styles.flag} /> });

    const handleClick = React.useCallback(
        (language: LanguageCode) => () => {
            dispatch(setLanguage(language));
            i18next.changeLanguage(language);
        },
        []
    );

    return (
        <div className={classnames("dropdown", classNames)}>
            <button
                className="btn dropdown-toggle d-flex align-items-center text-white"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {flagMap.get(selectedLanguage!)?.flag}
            </button>
            <ul className="dropdown-menu">
                {Array.from(flagMap.entries()).map(([key, value]) => {
                    return (
                        <li key={key}>
                            <button
                                className="dropdown-item"
                                onClick={handleClick(key)}
                                name={key}
                            >
                                {value.flag} {value.label}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default LanguageSelector;
