import { EMAIL_PATTERN, PASSWORD_PATTERN, PHONE_PATTERN } from "./config";
import { FormFieldProperty } from "./type";

type Field = "firstname" | "lastname" | "email" | "password" | "phone";

const getValidateField = (name: Field, value: string): FormFieldProperty => {
    switch (name) {
        case "email": {
            return { value, status: EMAIL_PATTERN.test(value) ? "valid" : "invalid" };
        }
        case "phone": {
            return { value, status: PHONE_PATTERN.test(value) ? "valid" : undefined };
        }
        case "password": {
            return { value, status: PASSWORD_PATTERN.test(value) ? "valid" : "invalid" }
        }
        default: {
            if (value.length > 0) return { value, status: "valid" };
            return { value, status: "invalid" };
        }
    }
};

export type { Field };
export { getValidateField };
