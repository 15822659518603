import React from "react";
import { useTranslation } from "react-i18next";

import PromotionSlide from "components/promotion-slide/promotion-slide";

import bikeSunsetImgSrc from "./assets/bike-sunset.jpg";
import danubeBendImgSrc from "./assets/danube-bend.jpg";
import questionImgSrc from "./assets/question.jpg";
import piggyBankImgSrc from "./assets/piggybank.jpg";
import adventureImgSrc from "./assets/adventure.jpeg";

import styles from "./home.module.css";

const Home: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-0 mt-md-4 d-flex flex-column">
            <PromotionSlide
                dir="right"
                question={t("What?")}
                answer={t(
                    "E-bike rental service offering high-quality electric bicycles for exploring the scenic Danube Bend."
                )}
                list={[
                    t(
                        "Variety of e-bike models suitable for different needs and preferences."
                    ),
                    t("Rental options including hourly, daily, and multi-day rates."),
                    t("Additional services such as route maps, and safety gear."),
                ]}
                imageSrc={bikeSunsetImgSrc}
            />

            <PromotionSlide
                dir="left"
                question={t("Where?")}
                answer={t(
                    "Located in Dömös, a picturesque village in the heart of the Danube Bend, Hungary."
                )}
                list={[
                    t(
                        "Central location in Dömös, easily accessible for tourists and locals."
                    ),
                    t(
                        "Proximity to popular attractions like the Prédikálószék viewpoint, Rám-szakadék canyon, and the Danube River."
                    ),
                    t("Convenient rental pick-up and drop-off points."),
                ]}
                imageSrc={danubeBendImgSrc}
            />

            <PromotionSlide
                dir="right"
                question={t("Why?")}
                answer={t(
                    "Discover the beauty of the Danube Bend with ease and sustainability."
                )}
                list={[
                    t(
                        "Scenic Beauty: Experience the stunning landscapes, historic sites, and natural wonders of the Danube Bend."
                    ),
                    t(
                        "Convenience: E-bikes make it easy to cover more ground with less effort, perfect for all fitness levels."
                    ),
                    t(
                        "Adventure: Enjoy the freedom to explore off-the-beaten-path locations that are difficult to reach by car or on foot."
                    ),
                ]}
                imageSrc={questionImgSrc}
            />

            <PromotionSlide
                dir="left"
                question={t("How much?")}
                answer={t(
                    "Our eBike rentals start at 3 000 Ft per hour. For longer rentals, we offer discounted daily and weekly rates."
                )}
                imageSrc={piggyBankImgSrc}
            >
                <table className={styles.priceList}>
                    <tr>
                        <td>{t("1 hour")}</td>
                        <td>3 000 Ft</td>
                    </tr>
                    <tr>
                        <td>{t("2 hours")}</td>
                        <td>6 000 Ft</td>
                    </tr>
                    <tr>
                        <td>{t("3 hours")}</td>
                        <td>8 000 Ft</td>
                    </tr>
                    <tr>
                        <td>{t("Daily")}*</td>
                        <td>12 000 Ft</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className={styles.smallLetters}>
                            *
                            {t(
                                "every rental surpassing 3 hours considered as daily rental"
                            )}
                        </td>
                    </tr>
                </table>
            </PromotionSlide>

            <PromotionSlide
                dir="left"
                question={t("How?")}
                answer={t("Write us, call us, visit us!")}
                list={[
                    "eridedomos@gmail.com",
                    "+36 30 215 5756",
                    "2027 Dömös Kossuth Lajos utca 43.",
                ]}
                imageSrc={adventureImgSrc}
            />
        </div>
    );
};

export default Home;
