import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeResponse } from "api/user/type";

import { UserStoreState } from "./type";

const initialState: Partial<UserStoreState> = {};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<MeResponse | undefined>) => {
            if (action.payload) {
                state.id = action.payload.id;
                state.email = action.payload.email;
                state.firstname = action.payload.firstname;
                state.lastname = action.payload.lastname;
                return;
            }

            state.id = undefined;
            state.email = undefined;
            state.firstname = undefined;
            state.lastname = undefined;
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
