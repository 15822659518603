import React from "react";
import classnames from "classnames";

interface FormFieldProps {
    id: string;
    type: "text" | "password" | "email" | "tel";
    className?: HTMLInputElement["className"];
    value?: string;
    name?: string;
    label?: string | JSX.Element;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    required?: boolean;
    status?: "valid" | "invalid";
    invalidFeedback?: string;
    pattern?: RegExp;
    title?: string;
}

const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
    const {
        id,
        name,
        type,
        label,
        className,
        value,
        onChange,
        placeholder,
        required,
        status,
        invalidFeedback,
        pattern,
        title,
    } = props;

    return (
        <React.Fragment>
            <label htmlFor={id}>
                {label} {required && <span className="text-danger">*</span>}
            </label>
            <input
                id={id}
                name={name}
                type={type}
                className={classnames(className, {
                    "is-invalid": status === "invalid",
                    "is-valid": status === "valid",
                })}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                pattern={pattern?.source}
                title={title}
            />
            <small className="invalid-feedback">{invalidFeedback}</small>
        </React.Fragment>
    );
};

export default FormField;
