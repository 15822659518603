import React from "react";

import styles from "./loading-stripe.module.css";
import classnames from "classnames";

type LoadingState = "loading" | "error" | "success";

interface LoadingIndicatorProps {
    loading?: LoadingState;
}

const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = (props) => {
    const { loading } = props;

    return (
        <div className={styles.loadingIndicator}>
            <div
                className={classnames(styles.loadingLine, {
                    [styles.animate]: loading === "loading",
                    [styles.error]: loading === "error",
                    [styles.success]: loading === "success",
                })}
            ></div>
        </div>
    );
};

export type { LoadingState };
export default LoadingIndicator;
