import { AxiosResponse } from "axios";
import { DOMAIN_BASE_URL } from "../authentication/error-codes";
import { MeResponse } from "./type";
import ebikeAPI from "../../configs/axios-config";
import RequestAbstract from "../request-abstract";

class UserApi extends RequestAbstract {
    constructor(baseURL: string) {
        super();
        this.baseURL = baseURL;
    }

    private baseURL: string;

    public async requestMeGet(): Promise<AxiosResponse<MeResponse>> {
        return await ebikeAPI.get(`${this.baseURL}/api/v1/user/me`, { withCredentials: true });
    }
}

export default new UserApi(DOMAIN_BASE_URL);