import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadingState } from "components/loading-stripe";

import { LanguageCode } from 'store/app/type';

import { AppState } from "./type";

const initialState: Partial<AppState> = {
    loading: undefined,
    language: "hu",
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<LoadingState>) => {
            state.loading = action.payload;
        },
        setLanguage: (state, action: PayloadAction<LanguageCode>) => {
            state.language = action.payload;
        },
    },
});

export const { setLoading, setLanguage } = appSlice.actions;
export default appSlice.reducer;
