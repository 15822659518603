import React from "react";
import { useAppSelector } from "hooks/store.hooks";

const Dashboard: React.FunctionComponent = () => {
    const user = useAppSelector((state) => state.user);

    return (
        <div>
            <h1>Hello {user.firstname}!</h1>
            <p>email: {user.email}</p>
        </div>
    );
};

export default Dashboard;
