import React from "react";
import classnames from "classnames";

import styles from "./promotion-slide.module.css";

interface PromotionSlideProps {
    dir: "right" | "left";
    question: string;
    answer: string;
    list?: string[];
    imageSrc: string;
    children?: React.ReactElement | string;
}

const PromotionSlide: React.FunctionComponent<PromotionSlideProps> = (props) => {
    const { dir, question, answer, list, imageSrc, children } = props;

    return (
        <div
            className={classnames(styles.promoSlide, styles[dir], "shadow rounded mt-4")}
        >
            <div
                className={classnames(styles.image, "rounded")}
                style={{ backgroundImage: `url(${imageSrc})` }}
            />
            <div className={styles.description}>
                <h1 className={classnames(styles.question, "shadow")}>{question}</h1>
                <div className={styles.content}>
                    <div
                        className={classnames(
                            styles.answer,
                            "bg-white rounded p-4 shadow border mb-3 mb-md-5"
                        )}
                    >
                        <p>{answer}</p>
                    </div>
                    {list && (
                        <ul>
                            {list.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PromotionSlide;
