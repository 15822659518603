import axios, { AxiosResponse } from "axios";
import {
    LoginFormDTO,
    RegistrationFromDTO,
    RegistrationResponse,
} from "./type";
import { DOMAIN_BASE_URL } from "./error-codes";
import RequestAbstract from "../request-abstract";

class AuthenticationApi extends RequestAbstract {
    constructor(baseURL: string) {
        super();
        this.baseURL = baseURL;
    }

    private baseURL: string;

    public async requestRegistrationPost(
        formData: RegistrationFromDTO
    ): Promise<AxiosResponse<RegistrationResponse>> {
        const response = await axios.post(
            `${this.baseURL}/api/v1/auth/register`,
            formData,
            { withCredentials: true }
        );

        return response;
    }

    public async requestLoginPost(formData: LoginFormDTO): Promise<AxiosResponse<void>> {
        const response = await axios.post(
            `${this.baseURL}/api/v1/auth/authenticate`,
            formData,
            { withCredentials: true }
        );

        return response;
    }

    public async requestLogoutPost(): Promise<AxiosResponse<void>> {
        const response = await axios.post(`${this.baseURL}/api/v1/auth/logout`, null, {
            withCredentials: true,
        });

        return response;
    }
}

export default new AuthenticationApi(DOMAIN_BASE_URL);
