import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ToastStoreState } from "./type";

const initialState: Partial<ToastStoreState> = {
    visible: false,
};

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        setToastContent: (state, action: PayloadAction<string | undefined>) => {
            state.content = action.payload;
        },
        setToastVisible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        }
    },
});

export const { setToastContent, setToastVisible } = toastSlice.actions;
export default toastSlice.reducer;
