import React from "react";
import classnames from "classnames";

import LanguageSelector from "components/language-selector";

import { EMAIL_ADDRESS, PHONE_NUMBER } from "configs/contacts";

import styles from "./info-bar.module.css";

interface InfoBarProps {
    className?: string;
}

const InfoBar: React.FunctionComponent<InfoBarProps> = (props) => {
    const { className } = props;

    return (
        <div
            className={classnames(
                "px-1 py-1 d-flex align-items-center justify-content-between ps-3 ps-md-0",
                styles.infoBar,
                className
            )}
        >
            <div>
                <a href={`tel:${PHONE_NUMBER}`}>
                    <i className="bi bi-telephone-fill me-1" />
                    {PHONE_NUMBER}
                </a>
                <a href={`mailto:${EMAIL_ADDRESS}`}>
                    <i className="bi bi-envelope-fill me-1 ms-1 ms-md-4" />
                    {EMAIL_ADDRESS}
                </a>
            </div>
            <LanguageSelector />
        </div>
    );
};

export default InfoBar;
